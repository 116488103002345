import React from 'react'
import Layout from '../components/layout'
import Meta from '../components/meta'
import { graphql } from 'gatsby'
import filterLocale from '../components/utils/filter-locale'

import Hero from '../components/about-us/hero'
import ToggleButton from '../components/about-us/toggle-button'

const AboutUs = ({ data: { aboutus } }) => {
	const locale = 'en-AU'
	const [{ node }] = filterLocale(aboutus.edges, locale)

	const {
		seoTitle,
		seoKeywords,
		seoDescription,
		body,
		backgroundImage,
	} = node

	const sources = [
		{
			...backgroundImage.fluid,
			srcSet: `${backgroundImage.fluid.src} 1920w`,
			srcSetWebp: `${backgroundImage.fluid.srcWebp} 1920w`,
			media: `(max-width: 1920px)`,
		},
	]
	return (
		<Layout>
			<Meta
				title={seoTitle}
				keywords={seoKeywords}
				description={seoDescription}
			/>
			<Hero {...{ seoTitle, seoDescription, body, backgroundImage, sources }} />
			<ToggleButton locale={locale}/>
		</Layout>
	)
}

export const AboutUsQuery = graphql`
	query AboutUsQuery {
		aboutus: allContentfulPage(filter: {contentful_id: {eq: "2KlTzLuxZRZYMYNCcZIqpJ"}}) {
			edges {
				node {
					seoTitle
					seoKeywords
					seoDescription
					node_locale
					slug
					backgroundImage {
						fluid(
							maxWidth: 1920
							maxHeight: 600
							quality: 80
							cropFocus: CENTER
							resizingBehavior: FILL
						) {
							src
							srcWebp
						}
					}
					body {
						json
					}
				}
			}
		}
	}
`

export default AboutUs
